
import * as types from "@/store/mutation-types";
import { claimsFormSchema } from "@/forms/shared/claims";
import {
  claimsMapActions,
  claimsMapMutations,
  claimsMapState
} from "@/store/modules/claims";
import Vue from "vue";
import { cloneDeep, get, omit, set } from "lodash";
import {
  AtlasClaimRepresentativeOptions,
  FieldAdjusterOptions,
  KindOfLossOptions,
  LEGACY_PHP_API_DATE_FORMAT
} from "@/helpers/constants";
import * as dateFns from "date-fns";
import { processPaymentOptionEdit } from "@/helpers/claims";
import { authMapGetters } from "@/store/modules/auth";
import { downloadClaimsDocument } from "@/helpers/downloadLossForm";
import { getLossMortgageAddress } from "@/helpers/lossFormDataProcessor";
import LossFormClaimInfo from "@/components/lossForm/LossFormClaimInfo.vue";
import ActivitiesMixin from "@/components/ActivitiesView/ActivitiesMixin.vue";

interface IClaims {
  errorMessage: string;
  receivePaymentOption: string;
  hasValidCredentials: boolean;
  resourceNotFound: boolean;
  successMessage: string;
  isInsuredFieldsValid: boolean;
  isAgentFieldValid: boolean;
  isPolicyInfoValid: boolean;
  validationData: any;
  lossFormData: any;
  pageDataInitialized: boolean;
}

export default Vue.extend({
  name: "claims",
  mixins: [ActivitiesMixin],
  components: {
    LossFormClaimInfo
  },
  data(): IClaims {
    return {
      receivePaymentOption: "",
      hasValidCredentials: false,
      resourceNotFound: false,
      errorMessage: "",
      successMessage: "",
      isInsuredFieldsValid: false,
      isAgentFieldValid: false,
      isPolicyInfoValid: false,
      validationData: {},
      pageDataInitialized: false,
      lossFormData: {}
    };
  },
  created() {
    this.initializePageData();
  },
  methods: {
    ...claimsMapActions(["updateClaims", "getClaim"]),
    ...claimsMapMutations({
      editField: types.SET_EDIT_FIELD,
      setEdit: types.SET_EDIT
    }),
    getValue(dataObject: object, key: string): string {
      return get(dataObject, key, "") || "";
    },
    addHardCodedFields(): void {
      //Fields we are legally required to hardcode
      this.lossFormData.role = this.$getCurrentUser.role;
      this.lossFormData.agentCode = this.lossFormData.formItem.agentCode;
      this.lossFormData.site = "ATLAS";
      this.lossFormData["policyNumber"] =
        get(this.lossFormData, "lossFormOtherInfo.Client.PolicyNum", "") || "";
      this.lossFormData["companyPrefix"] = this.lossFormData.policyNumber.split(
        " "
      )[0];
      this.lossFormData.isEditing = true;
    },
    async initializePageData() {
      try {
        await this.getClaim(this.claimId);
        const { data, ...rootFields } = this.editing as any;
        this.lossFormData = { ...rootFields, ...data };
        this.lossFormData.mortgageInfo = getLossMortgageAddress(
          this.lossFormData
        );
        this.addHardCodedFields();
        this.pageDataInitialized = true;
      } catch (error) {
        this.resourceNotFound = true;
        this.$bugSnagClient.notify(error);
      }
    },
    async formFieldChangedHandler({ key, value }: { key: string; value: any }) {
      const dateFields = [
        "lossFormOtherInfo.FormInfo.LossDate",
        "lossFormOtherInfo.Coverages.DateAssigned"
      ];
      if (value instanceof Date || dateFields.includes(key)) {
        set(
          this.lossFormData,
          key,
          dateFns.format(value, LEGACY_PHP_API_DATE_FORMAT)
        );
      } else if (key === "lossFormOtherInfo.FormInfo.paymentOption") {
        await processPaymentOptionEdit.bind(this)(
          key,
          value,
          this.lossFormData
        );
      } else if (
        key === "lossFormOtherInfo.FormInfo.ClassId" &&
        value !== "21"
      ) {
        set(this.lossFormData, "lossFormOtherInfo.FormInfo.ClaimAntName", "");
        set(this.lossFormData, "lossFormOtherInfo.FormInfo.ClaimAntPhone", "");
        set(
          this.lossFormData,
          "lossFormOtherInfo.FormInfo.ClaimAntAddress",
          ""
        );
        set(this.lossFormData, key, value);
      } else {
        set(this.lossFormData, key, value);
      }
      this.lossFormData = cloneDeep(this.lossFormData);
    },
    async updateLossForm(_type = "update"): Promise<void> {
      try {
        const toOmit = ["mortgageInfo"];
        if (_type === "VS" || _type === "VR")
          this.lossFormData["brk_pt"] = _type;
        this.lossFormData.lossFormRequire.paymentOption =
          get(
            this.lossFormData,
            "lossFormOtherInfo.FormInfo.paymentOption",
            ""
          ) || "";
        this.lossFormData.lossFormRequire = {
          ...this.lossFormData.lossFormRequire,
          ...this.lossFormData.formItem
        };
        await this.updateClaims({
          id: this.claimId,
          update: omit(this.lossFormData, toOmit)
        });
        this.$appNotifySuccess("Loss Form updated.");
        this.$router.push("/claims").catch(() => {});
      } catch (e) {
        this.errorMessage = e.message || "Error Submitting Claims";
      } finally {
        this.$emit("scrollTop");
      }
    },
    async onToolbarItemSelected(action: string): Promise<void> {
      if (action === "update") {
        this.updateLossForm();
      }
      if (action === "VS" || action === "VR") {
        this.updateLossForm(action);
      }

      if (action === "print") {
        await downloadClaimsDocument({
          _ids: [this.lossFormData._id],
          policyPrefix: this.lossFormData.companyPrefix
        });
      }
      if (action === "cancel") {
        this.$router.go(-1);
      }
    }
  },
  computed: {
    ...claimsMapState(["editing", "makingApiRequest"]),
    ...authMapGetters(["getCurrentUser"]),
    dynamicFormBuilderOptions(): any {
      return {
        "lossFormOtherInfo.FormInfo.ClassId": [...KindOfLossOptions],
        "lossFormOtherInfo.Coverages.FieldAssigned": [...FieldAdjusterOptions],
        "lossFormOtherInfo.Coverages.ClaimRepresentative": [
          ...AtlasClaimRepresentativeOptions
        ]
      };
    },
    claimId(): string {
      return this.$route.params._id;
    },
    fieldsWithIssues(): string[] {
      if (this.validationData.fieldsWithErrors) {
        return Object.values(this.validationData.fieldLabels);
      }
      return [];
    },
    lossEditActions(): any[] {
      const actions = [
        { title: "Cancel", command: "cancel" },
        { title: "Print", command: "print" }
      ];
      if (this.getCurrentUser?.role === "admin") {
        actions.push({ title: "View Sending XML", command: "VS" });
        actions.push({ title: "View Receiving XML", command: "VR" });
      }
      return actions;
    },
    claimFormFields(): any[] {
      let viewingState = this.editing?.deleted ? "viewing" : "editing";
      return claimsFormSchema(
        "update",
        viewingState,
        this.lossFormData.lossFormOtherInfo.Client.OtherAuthConcact,
        this.lossFormData.lossFormOtherInfo.Client.OtherPhone,
        this.lossFormData.lossFormOtherInfo.Coverages.WindExcludeStatement
      );
    },
    queryOverride(): any {
      return {
        "data.claimsRequestId": this.editing?._id,
        activityType: "ClaimsRequestActivity"
      };
    }
  }
});
